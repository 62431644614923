.footer {
  padding-top: 70px;
  padding-bottom: 70px;
  background-image: url(../img/banners/footer-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 560px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &-top {
    padding-bottom: 60px;

    @media (max-width: 560px) {
      padding-bottom: 50px;
    }
  }

  &-logo {
    img {
      margin-bottom: 30px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: justify;
      color: #F1F1F1;
      width: 80%;
    }
    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
  }

  &-separator {
    margin: 40px 0;
    border-top: 1px solid $gray-100;

    @media (max-width: 1440px) {
      margin: 30px 0;
    }

    @media (max-width: 1024px) {
      margin: 25px 0;
    }

    @media (max-width: 560px) {
      margin: 0 0 35px;
    }
  }

  &-menu {
    p {
      color: $black-25;
    }

    &-title {
      padding-bottom: 6px;
      border-bottom: 2px solid $white;
      width: fit-content;
      margin-bottom: 20px;
      p {
        font-size: 24px;
        font-weight: 600;
        color: $white;
        text-transform: capitalize;
      }
    }
  }

  &-link {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 27px;

    @media (max-width: 560px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-about {
    &-big-text {
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
      color: #FFF !important;
    }

    &-text {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin-block: 12px;
      color: #F1F1F1 !important;
    }
  }
}

.copyright {
  p {
    font-size: 14px;
    color: $white;
  }
}

.social-icon-wrap {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $gray-100;
  color: $white;
  font-size: 15px;
  margin-right: 10px;
  transition: all .3s ease;

  &:hover {
    background: $primary;
    color: $white;
  }
}
