#team {
    padding-block: 50px;
}

.team {
    &-wrap {
        border-radius: 22px;
        background-image: url(../img/banners/vector-bg.png);
        overflow: hidden;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 40px;
        position: relative;
        height: 83vh;
        padding: 30px 65px;
        background-position: 0px -34px;
        @media (max-width: 1366px) {
            gap: 25px;
        }

        @media (max-width: 1280px) {
            gap: 5px;
        }

        @media (max-width: 1200px) {
            padding: 50px 35px;
        }

        @media (max-width: 768px) {
            padding: 50px 20px;
            background-size: auto;
        }

        @media (max-width: 560px) {
            grid-template-columns: repeat(2, 1fr);
            padding: 25px;
            height: fit-content;
        }

        @media (max-width: 400px) {
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    &-text {
        margin-bottom: 70px;
    }

    &-about {
        position: relative;
        height: 100%;
        text-align: center;

        @media (max-width: 560px) {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            padding: 15px 0;
        }

        &:nth-of-type(1), &:nth-of-type(5) {
            @media (min-width: 768px) {
                top: 35%;
            }
        }

        &:nth-of-type(2), &:nth-of-type(4) {
            @media (min-width: 768px) {
                top: 15%;
            }
        }

        &::after {
            position: absolute;
            content: '';
            width: 3px;
            height: 100px;
            border-radius: 10px;
            background: linear-gradient(180deg, rgba(241,241,241,1) 0%, rgba(241,241,241,0.5) 50%, rgba(241,241,241,0) 100%);
            top: 32%;
            left: 45%;

            @media (max-width: 560px) {
                display: none;
            }
        }

        

        &:nth-of-type(3) {
            &::after {
                height: 145px;

                @media (max-width: 1680px) {
                    height: 115px;
                }
            }
        }

        .name {
            font-size: 25px;
            font-weight: 600;
            font-style: italic;

            @media (max-width: 1680px) {
                font-size: 22px;
            }

            @media (max-width: 1440px) {
                font-size: 18px;
            }

            @media (max-width: 820px) {
                font-size: 12px;
            }
        }

        .position {
            font-size: 20px;
            font-style: italic;

            @media (max-width: 1680px) {
                font-size: 18px;
            }

            @media (max-width: 1440px) {
                font-size: 16px;
            }

            @media (max-width: 820px) {
                font-size: 12px;
            }
        }
    }

    &-quote {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 2px;

        @media (max-width: 560px) {
            font-size: 18px;
        }

        span {
            font-size: 36px;
            line-height: 58px;

            @media (max-width: 560px) {
                font-size: 28px;
                line-height: 48px;
            }
        }
    }
}
