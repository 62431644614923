.subscribe-content {
    font-size: 18px;
    line-height: 34px;
    width: 60%;
    text-align: justify;

    @media (max-width: 1680px) {
        width: 70%;
    }

    @media (max-width: 1440px) {
        font-size: 20px;
        line-height: 32px;
    }

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 820px) {
        width: 90%;
        font-size: 16px;
        line-height: 28px;
    }

}

.upper-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 26px;

    &:last-of-type {
        margin-right: 0;
    }

    @media (max-width: 560px) {
        margin-right: 15px;
    }

    svg {
        margin-right: 20px;

        @media (max-width: 560px) {
            margin-right: 11px;
        }
    }
}

.input-upper-text {
    margin: 125px 30px 20px;

    @media (max-width: 560px) {
        margin: 125px 0 20px;
    }
}

.input-wrap {
    display: flex;
    justify-content: center;
    transition: all .3s ease;

    .double-button-wrap {
        width: 70%;

        @media (max-width: 560px) {
            width: 78%;
        }

        &:hover {
            .border-gradient-btn {
                width: 100.4%;
            }
        }
    }

    .border-gradient-btn {
        width: 101%;

        &::before {
            width: 99.7%;
            left: 2px;

            @media (max-width: 1280px) {
                width: 99.6%;
            }

            @media (max-width: 992px) {
                width: 99.5%;
            }

            @media (max-width: 768px) {
                width: 99.1%;
            }
        }
    }
}
.subscribe-wrap {
    padding-top: 90px;
}
.newsletter-wrap {
    padding: 9px 0 150px 0px;
    background-image: url(../img/banners/newsletter-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 70px;
}

.newsletter-input {
    padding-left: 27px;
    border-radius: 12px;
    background: $white;
    position: relative;
    z-index: 1;
    transition: all .3s ease;

    @media (max-width: 560px) {
        padding-left: 10px;
    }
}

.custom-input {
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 1.5px;
    color: $black-50;
    width: 80%;
    border: none;
    outline: none;
    padding: 10px 0;

    @media (max-width: 1680px) {
        font-size: 36px;
        line-height: 53px;
    }

    @media (max-width: 1600px) {
        font-size: 34px;
        line-height: 51px;
    }

    @media (max-width: 1440px) {
        font-size: 30px;
        line-height: 46px;
    }

    @media (max-width: 768px) {
        font-size: 25px;
        line-height: 38px;
    }

    @media (max-width: 560px) {
        width: 50%;
        font-size: 18px;
        letter-spacing: 1px;
    }

    &::placeholder {
        color: $gray;
    }
}

.newsletter-btn {
    background: #171717;
    border: 1px solid #fff;
    padding: 15.5px;
    color: #fff;
    font-size: 35px;
    border-radius: 12px;
    position: absolute;
    right: -3px;
    top: 0px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25), -10px -10px 20px rgba(0, 255, 255, 0.05);
    transition: all .3s ease;

    @media (max-width: 1280px) {
        font-size: 25px;
    }

    @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 25px;
    }

    svg {
        font-size: 30px;
        margin-left: 10px;

        @media (max-width: 768px) {
            font-size: 18px;
        }
    }

    &:hover {
        color: $secondary;
    }
}

.bottom-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 39px;
}

.bottom-subtitle {
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;
    margin-bottom: 13px;
}

.text-icon {
    margin-bottom: 17px;

    svg {
        height: 55px;
    }
}