.blog {
    &-banner {
        padding-top: 270px;
        padding-bottom: 170px;
        position: relative;
        z-index: 999;

        @media (max-width: 820px) {
            padding-top: 160px;
        }
        
        .banner-btn {
            padding: 9px 33px;
            font-size: 30px;
            line-height: 42px;
            font-weight: 400;

            @media (max-width: 820px) {
                font-size: 22px;
                line-height: 28px;
            }
        }
    }

    &-newsletter-title {
        font-weight: 500;
        font-size: 38px;
        line-height: 53px;
        color: $gray-200;
        margin: 50px 0 20px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 43px;
        }
        @media (max-width: 560px) {
            margin: 0 0 15px;
        }
    }

    &-pattern {
        .bg-pattern {
            top: -590px;
        }
    }

    &-img-wrap {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
            height: 100%;
        }
    }
}

.slick-initialized .slick-slide {
    padding-inline: 20px;
    margin-top: 90px;
}

.slick-slider .slick-track, .slick-slider .slick-list {
    height: 500px;

    @media (max-width: 1440px) {
        height: 600px;
    }
    @media (max-width: 1280px) {
        height: 650px;
    }
    @media (max-width: 1200px) {
        height: 500px;
    }
    @media (max-width: 560px) {
        height: 750px;
    }
}

.slick-prev:before, .slick-next:before {
    content: '';
}

.slick-next, .slick-prev {
    height: 85px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    border-radius: 12px;
    transition: all .3s ease;

    @media (max-width: 768px) {
        height: 70px;
        width: 35px;
        border-radius: 10px;
    }

    @media (max-width: 560px) {
        height: 60px;
        width: 30px;
        border-radius: 5px;
    }
}

.slick-next:hover, .slick-prev:hover {
    background: rgba($color: $primary, $alpha: 0.3);

    svg {
        color: $white;
    }
}

.slick-prev {
    svg {
        transform: rotate(180deg);
    }
}
.slick-prev {
    left: -50px;

    @media (max-width: 768px) {
        left: -30px;
    }
}
.slick-next {
    right: -50px;

    @media (max-width: 768px) {
        right: -30px;
    }
}