main {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   background:  $dark;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 9999999;
    animation: hide 6s linear forwards;
}

.dank-ass-loader {
   display: flex;
   flex-direction: column;
   align-items: center;
   
   .loader-row {
      display: flex;
   }
}

.arrow {
   width: 0; 
   height: 0;
   margin: 0 (-$size / 2);
   border-left: $size solid transparent;
   border-right: $size solid transparent;
   border-bottom: ($size * 1.8) solid $secondary;
   animation: blink $time + s infinite;
   filter: drop-shadow(0 0 ($size * 1.5) $secondary);
    
   &.down {
      transform: rotate(180deg);
   }
   
   @for $i from 1 through 18 {
      &.outer-#{$i} {
         animation-delay: -($time / 18) * $i + s;
      }  
   }

   @for $i from 1 through 6 {
      &.inner-#{$i} {
         animation-delay: -($time / 6) * $i + s;
      }  
   }  
}

@keyframes blink {
  0% { opacity: 0.1; }
  30% { opacity: 1; }
  100% { opacity: 0.1; }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}