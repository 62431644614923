.dark {
    &-card {
        &-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 37.1613px;
            gap: 26.73px;
            isolation: isolate;

            background: rgba( 255, 255, 255, 0.15 );
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            backdrop-filter: blur( 9.5px );
            -webkit-backdrop-filter: blur( 9.5px );
            box-shadow: 3.71613px 3.71613px 18.5806px rgba(174, 172, 255, 0.2);

            border-radius: 10.6934px;
            min-height: 255px;

            @media (max-width: 560px) {
                padding: 25px;
            }
        }

        &-title {
            font-weight: 600;
            font-size: 42px;
            line-height: 59px;
            letter-spacing: 0.03em;
            color: #FFEEC7;

            @media (max-width: 1024px) {
                font-size: 35px;
                line-height: 46px;
            }
            @media (max-width: 820px) {
                font-size: 30px;
                line-height: 42px;
            }
            @media (max-width: 560px) {
                font-size: 25px;
                line-height: 36px;
                margin-top: 25px;
            }
        }

        &-desc {
            font-weight: 500;
            font-size: 24px;
            line-height: 33px;
            letter-spacing: 0.03em;
            color: #FFFFFF;

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 28px;            
            }
            @media (max-width: 560px) {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
}

.insight {
    &-section {
        // padding-bottom: 120px;
        // margin-top: -85px;
        // padding-top: 85px;
    }

    &-row {
        padding-top: 90px;
        z-index: 2;
    }
}

.bg-pattern {
    position: absolute;
    top: -170px;
    left: 0;
    width: 100%;
    z-index: 1;
}
#team{
    padding-top: 100px;
}