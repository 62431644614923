.instruction {
    &-wrap {
        padding: 227px 145px 97px;

        @media (max-width: 1440px) {
            padding: 200px 100px 85px;
        }

        @media (max-width: 1024px) {
            padding: 155px 70px 85px;
        }

        @media (max-width: 992px) {
            padding: 120px 60px 85px;
        }

        @media (max-width: 600px) {
            padding: 120px 35px 85px;
        }
    }

    &-box {
        background: $black;
        border-radius: 30px;
    }
}

.box {
    &-header {
        padding: 45px 100px;
        border-bottom: 1px solid $black-50;
        @media (max-width: 1440px) {
            padding: 30px 70px;
        }

        @media (max-width: 1280px) {
            padding: 30px 55px;
        }

        @media (max-width: 992px) {
            padding: 30px 40px;
        }

        @media (max-width: 600px) {
            padding: 30px;
        }

        @media (max-width: 400px) {
            padding: 25px 20px;
        }

        p {
            font-weight: 600;
        }
    }

    &-body {
        padding: 62px 100px;

        @media (max-width: 1440px) {
            padding: 55px 70px;
        }

        @media (max-width: 1280px) {
            padding: 55px;
        }

        @media (max-width: 992px) {
            padding: 40px;
        }

        @media (max-width: 600px) {
            padding: 40px 30px;
        }

        @media (max-width: 400px) {
            padding: 40px 20px;
        }
    }

    &-footer {
        padding: 45px 100px;
        border-top: 1px solid $black-50;

        @media (max-width: 1440px) {
            padding: 30px 70px;
        }

        @media (max-width: 1280px) {
            padding: 30px 55px;
        }

        @media (max-width: 992px) {
            padding: 30px 40px;
        }

        @media (max-width: 600px) {
            padding: 30px;
        }

        @media (max-width: 400px) {
            padding: 25px 20px;
        }
    }
}

.btn-gradient {    
    padding: 2px;
    background: $primary-gradient;
    border-radius: 50px;
    overflow: hidden;

    &.btn-disabled {
        background: none;
    }

    @media (max-width: 1024px) {
        border-radius: 55px;
    }

    @media (max-width: 400px) {
        padding: 1px;
    }

    .disable-btn {
        background: $black-50;
        padding: 20px 50px;
        font-size: 32px;
        line-height: 36px;
        color: $black-25;
        border-radius: 50px;

        @media (max-width: 1680px) {
            font-size: 28px;
            line-height: 32px;
        }

        @media (max-width: 1600px) {
            font-size: 25px;
            line-height: 29px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 26px;
        }

        @media (max-width: 1024px) {
            font-size: 20px;
            line-height: 24px;
            border-radius: 55px;
            padding: 15px 40px;
        }

        @media (max-width: 992px) {
            font-size: 18px;
            line-height: 22px;
        }

        @media (max-width: 820px) {
            padding: 10px 40px;
        }

        @media (max-width: 600px) {
            font-size: 16px;
            line-height: 20px;
        }

        @media (max-width: 400px) {
            font-size: 14px;
            line-height: 18px;
            padding: 10px 30px;
        }
    }

    .btn-text {
        &-wrap {
            padding: 20px 50px;
            font-size: 32px;
            line-height: 36px;
            color: $white;
            transition: all .3s ease;
            background: transparent;
            border-radius: 50px;
            @media (max-width: 1680px) {
                font-size: 28px;
                line-height: 32px;
            }

            @media (max-width: 1600px) {
                font-size: 25px;
                line-height: 29px;
            }

            @media (max-width: 1200px) {
                font-size: 22px;
                line-height: 26px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 24px;
                border-radius: 55px;
                padding: 15px 40px;
            }

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (max-width: 820px) {
                padding: 10px 40px;
            }

            @media (max-width: 600px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 400px) {
                font-size: 14px;
                line-height: 18px;
                padding: 10px 30px;
            }

            &:hover {
                background: $black;
            }
        }

        &-reverse-wrap {
            padding: 20px 50px;
            font-size: 32px;
            line-height: 36px;
            color: $white;
            transition: all .3s ease;
            background: $black;
            border-radius: 50px;

            @media (max-width: 1680px) {
                font-size: 28px;
                line-height: 32px;
            }

            @media (max-width: 1600px) {
                font-size: 25px;
                line-height: 29px;
            }

            @media (max-width: 1200px) {
                font-size: 22px;
                line-height: 26px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 24px;
                border-radius: 55px;
                padding: 15px 40px;
            }

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (max-width: 820px) {
                padding: 10px 40px;
            }

            @media (max-width: 600px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 400px) {
                font-size: 14px;
                line-height: 18px;
                padding: 10px 30px;
            }

            &:hover {
                background: transparent;
            }
        }
    }
}

.list-item {
    padding-left: 100px;
    position: relative;
    margin-bottom: 45px;
    padding-top: 12px;

    @media (max-width: 1280px) {
        padding-left: 80px;
        padding-top: 10px;
    }

    @media (max-width: 1024px) {
        padding-left: 70px;
        padding-top: 8px;
    }

    @media (max-width: 992px) {
        padding-left: 60px;
        margin-bottom: 35px;
    }

    @media (max-width: 600px) {
        padding-left: 45px;
        padding-top: 4px;
    }

    span {
        &::before {
            width: 60px;
            height: 60px;
            background: #D9D9D9;
            border-radius: 50%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: 1280px) {
                width: 50px;
                height: 50px;
            }

            @media (max-width: 1024px) {
                width: 40px;
                height: 40px;
            }

            @media (max-width: 600px) {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.camera-img {
    min-width: 100%;
    min-height: 100%;
    
    &-wrap {
        width: 100%;
        height: 500px;
        padding: 3px;
        // background: $primary-gradient;
        border-radius: 5px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;

        @media (max-width: 1600px) {
            height: 450px;
        }

        @media (max-width: 1024px) {
            height: 400px;
        }

        @media (max-width: 820px) {
            height: 420px;
        }

        @media (max-width: 600px) {
            height: 300px;
        }
    }
}