.tokenomics-wrap {
    padding-block: 170px;
    padding-right: 100px;
    padding-bottom: 0px;

    @media (max-width: 1024px) {
        padding-block: 0;
        padding-inline: 50px;
    }

    @media (max-width: 400px) {
        padding-inline: 30px;
    }
}

.chart-bg {
    background: url(../img/banners/chart-bg.png);
    padding: 0px 130px 135px;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;

    canvas {
        position: relative;
        z-index: 1;
    }

    @media (max-width: 1440px) {
        padding: 56px 80px 135px;
    }

    @media (max-width: 1366px) {
        padding: 56px 65px 135px;
    }

    @media (max-width: 1200px) {
        padding: 56px 45px 115px;
    }

    @media (max-width: 560px) {
        padding: 0px 0 115px;
        background-size: cover;
    }
}

.token-circle {
    width: 420px;
    height: 420px;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    top: 15%;
    left: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    img {
        @media (max-width: 1680px) {
            width: 55%;
        }
    }

    @media (max-width: 1680px) {
        width: 340px;
        height: 340px;
        top: 10.5%;
        left: 28%;
    }

    @media (max-width: 1600px) {
        width: 300px;
        height: 300px;
        top: 10.5%;
        left: 30%;
    }

    @media (max-width: 1440px) {
        top: 34.5%;
        left: 27%;
    }

    @media (max-width: 1366px) {
        top: 34%;
        left: 26%;
    }

    @media (max-width: 1366px) {
        top: 36%;
        width: 280px;
        height: 280px;
    }

    @media (max-width: 1200px) {
        top: 36.5%;
        left: 27%;
        width: 250px;
        height: 250px;
    }

    @media (max-width: 1024px) {
        top: 31.5%;
        width: 420px;
        height: 420px;
    }

    @media (max-width: 820px) {
        top: 27.5%;
        left: 21%;
    }

    @media (max-width: 768px) {
        top: 30%;
        left: 23%;
        width: 365px;
        height: 365px;
    }

    @media (max-width: 600px) {
        top: 41%;
        left: 30%;
        width: 170px;
        height: 170px;
    }

    @media (max-width: 400px) {
        top: 42%;
        left: 29.5%;
        width: 180px;
        height: 180px;
    }
}


.data {
    &-box {
        height: 100%;
        padding: 22px 40px 21px 40px;
        background: linear-gradient(228.01deg, #4A4987 0%, rgba(53, 53, 87, 0.2) 100%);
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25), -10px -10px 20px rgba(0, 255, 255, 0.05);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width: 1680px) {
            padding: 20px 40px 21px 40px;
        }
    }

    &-title {
        min-height: 60px;
        padding-bottom: 30px;
        position: relative;
        margin-bottom: 20px;

      

        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            color: $white;

            @media (max-width: 1680px) {
                font-size: 18px;
            }

            @media (max-width: 1440px) {
                font-size: 24px;
            }

            @media (max-width: 1366px) {
                font-size: 22px;
                line-height: 27px;
            }
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 60%;
            height: 3px;
            border-radius: 5px;
            background: $white;
        }
    }

    &-value {
        font-size: 30px;

        font-weight: 600;


    }
}