.banner {
    &-wrap {
        height: 135vh;
        display: flex;
        flex-direction: column;
        padding-top: 345px;

        @media (max-width: 1600px) {
            padding-top: 300px;
        }

        @media (max-width: 560px) {
            height: auto;
            padding-bottom: 150px;
        }
    }

    &-text {
        font-size: 90px;
        font-weight: 700;
        text-transform: uppercase;
        color: $white;
        text-shadow: 0px 4.89173px 34.2421px rgba(255, 255, 255, 0.33);

        @media (max-width: 1200px) {
            font-size: 80px;
        }

        @media (max-width: 400px) {
            font-size: 50px;
            line-height: 65px;
        }
    }

    &-content {
        font-size: 32px;
        font-weight: 600;
        width: 60%;
        margin-top: 40px;
        line-height: 48px;

        @media (max-width: 1600px) {
            font-size: 28px;
            line-height: 42px;
        }

        @media (max-width: 1440px) {
            width: 35%;
        }

        @media (max-width: 1200px) {
            width: 45%;
        }

        @media (max-width: 560px) {
            width: 100%;
        }

        @media (max-width: 400px) {
            font-size: 22px;
            line-height: 35px;
            margin-top: 15px;
        }
    }
}

.content {
    text-align: justify;
    width: 95%;
}

.about-section {
    @media (max-width: 1680px) {
        padding-top: 130px;
    }
}

.solution {
    &-banner-wrap {
        padding: 10px;
        background: $black;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25), -10px -10px 20px rgba(0, 255, 255, 0.05);

        img {
            width: 100%;
        }
    }

    &-wrap {
        background-image: url(../img/banners/bg-pattern.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
        padding-block: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 560px) {
            background-size: cover;
        }
    }
}

.section-btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1440px) {
        margin-top: 50px;
    }
}

.btn-decoration-line {
    margin-right: 15px;
    position: relative;
    width: 225px;
    height: 3px;
    border-radius: 5px;
    background: $white;

    @media (max-width: 1366px) {
        width: 170px;
    }
    
    &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $white;
        left: -4px;
        top: -4px;
    }
}

.double-button-wrap {
    position: relative;

    &:hover {
        .border-btn {
            box-shadow: none;
            // background: $primary-gradient;
            border-color: transparent;
        }

        .border-gradient-btn {
            transform: translate(-1px, -1px);
        }
    }
}

.border-btn {
    padding: 15px;
    font-size: 35px;
    color: $white;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid $white;
    background: $dark;
    border-radius: 12px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25), -10px -10px 20px rgba(0, 255, 255, 0.05);
    position: relative;
    z-index: 1;
    transition: all .3s ease;

    @media (max-width: 1600px) {
        font-size: 30px;
    }

    @media (max-width: 1280px) {
        font-size: 26px;
    }

    @media (max-width: 560px) {
        font-size: 18px;
        line-height: 22px;
    }

    svg {
        font-size: 30px;
        margin-left: 20px;

        @media (max-width: 560px) {
            font-size: 17px;
            margin-left: 20px;
        }
    }
}

.border-gradient-btn {
    background: $primary-gradient;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 103%;
    height: 103%;
    z-index: 0;
    transform: translate(13px, 17px);
    transition: all .3s ease;

    &::before {
        width: 99.5%;
        height: 97%;
        background: $dark;
        border-radius: 12px;
        position: absolute;
        content: '';
        top: 1px;
        left: 1px;

        @media (max-width: 1880px) {
            width: 99%;
            height: 97%;
        }

        @media (max-width: 1280px) {
            width: 98.5%;
            height: 97%;
        }

        @media (max-width: 820px) {
            height: 96%;
        }
    }
}

.coin-content-wrap {
    @media (max-width: 1600px) {
        padding-top: 30px;
    }
}

.coin-about {
    background-image: url(../img/banners/milkiway.png);
    background-position: center;
    padding-top: 125px;
    padding-bottom: 30px;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1600px) {
        padding-top: 55px;
        padding-bottom: 0;
    }

    @media (max-width: 820px) {
        img {
            width: 80%;
        }
    }
}

.buy {
    &-content {
        margin: 35px 0;
    }
}

.home-page {
    background-image: url(../img/banners/banner-bg.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% contain;

    @media (max-width: 560px) {
        background-size: 150vh;
    }
}
#about {
    padding-top: 120px;
  
}