@import url(https://fonts.googleapis.com/css?family=Mohave:300,regular,500,600,700,300italic,italic,500italic,600italic,700italic);

* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Mohave", sans-serif;
  font-size: 30px;
  color: $white;
  font-weight: 400;
  background-color: $dark;
  
  line-height: 42px;
  scroll-behavior: smooth;

  @media (max-width: 1680px) {
    font-size: 26px;
    line-height: 40px;
  }

  @media (max-width: 1600px) {
    font-size: 23px;
    line-height: 38px;
  }

  @media (max-width: 1440px) {
    font-size: 20px;
    line-height: 33px;
  }

  @media (max-width: 1280px) {
    font-size: 17px;
    line-height: 28px;
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 35px;
  }

  @media (max-width: 820px) {
    font-size: 18px;
    line-height: 30px;
    overflow-x: hidden;
  }
}

a {
  text-decoration: none;
  color: $black-25;

  &:hover {
    color: $primary;
  }
}

ol,
ul,
li {
  list-style: none;
}

button {
  border: none;
  background: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

P {
  color: $black-10;
  letter-spacing: 1.5px;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol {
  padding: 0;
  margin: 0;
}

.padding {
  &-inline {
    padding-left: 120px;
    padding-right:120px;

    @media (max-width: 1680px) {
      padding-inline: 70px;
    }

    @media (max-width: 1024px) {
      padding-inline: 50px;
    }

    @media (max-width: 820px) {
      padding-inline: 40px;
    }

    @media (max-width: 560px) {
      padding-inline: 35px;
    }
  }

  &-block {
    padding-block: 65px;

    @media (max-width: 1440px) {
      padding-block: 100px;
    }

    @media (max-width: 560px) {
      padding-block: 75px;
    }
  }

  &-bottom {
    padding-bottom: 123px;

    @media (max-width: 1440px) {
      padding-bottom: 100px;
    }

    @media (max-width: 560px) {
      padding-bottom: 75px;
    }
  }
}

.logo {
  @media (max-width: 1024px) {
    width: 80px;
  }
}

.text-12 {
  font-size: 12px;
  line-height: 15px;
}

.title-72,
.text-72 {
  font-size: 72px;
  font-weight: 700;
  line-height: 92px;

  @media (max-width: 1680px) {
    font-size: 70px;
    line-height: 90px;
  }

  @media (max-width: 1440px) {
    font-size: 60px;
    line-height: 85px;
  }

  @media (max-width: 1280px) {
    font-size: 55px;
    line-height: 80px;
  }

  @media (max-width: 1024px) {
    font-size: 45px;
    line-height: 60px;
  }

  @media (max-width: 820px) {
    font-size: 35px;
    line-height: 50px;
  }
}

.text-24 {
  font-size: 24px;
  line-height: 38px;

  @media (max-width: 1680px) {
    font-size: 22px;
    line-height: 36px;
  }

  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 33px;
  }

  @media (max-width: 1366px) {
    font-size: 16px;
    line-height: 30px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 25px;
  }

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 15px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.text-32 {
  font-size: 32px;
  line-height: 36px;

  @media (max-width: 1680px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (max-width: 1600px) {
    font-size: 25px;
    line-height: 29px;
  }

  @media (max-width: 1200px) {
    font-size: 22px;
    line-height: 26px;
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.text-36 {
  font-size: 36px;
  line-height: 45px;

  @media (max-width: 1880px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media (max-width: 1680px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: 1440px) {
    font-size: 22px;
    line-height: 35px;
  }

  @media (max-width: 1366px) {
    font-size: 25px;
    line-height: 32px;
  }

  @media (max-width: 1366px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 1280px) {
    font-size: 15px;
    line-height: 25px;
  }
}

.text-14 {
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 24px;
  }

  @media (max-width: 820px) {
    font-size: 8px;
    line-height: 12px;
  }
}

.text-40 {
  font-size: 40px;
  line-height: 44px;

  @media (max-width: 1440px) {
    font-size: 35px;
    line-height: 39px;
  }

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 36px;
  }

  @media (max-width: 1024px) {
    font-size: 27px;
    line-height: 35px;
  }
}

.text-48 {
  font-size: 48px;
  line-height: 48px;

  &.title {
    font-weight: 600;
  }

  @media (max-width: 1680px) {
    font-size: 45px;
  }

  @media (max-width: 1440px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: 1280px) {
    font-size: 35px;
    line-height: 48px;
  }

  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 40px;
  }

  @media (max-width: 820px) {
    font-size: 20px;
    line-height: 35px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 29px;
  }
}

.text-64 {
  font-size: 64px;
  line-height: 81px;

  @media (max-width: 1680px) {
    font-size: 62px;
    line-height: 80px;
  }

  @media (max-width: 1440px) {
    font-size: 58px;
    line-height: 76px;
  }

  @media (max-width: 1280px) {
    font-size: 55px;
    line-height: 70px;
  }

  @media (max-width: 1024px) {
    font-size: 45px;
    line-height: 65px;
  }

  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 45px;
  }

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.text-96 {
  font-size: 96px;
  line-height: 121px;

  @media (max-width: 1680px) {
    font-size: 93px;
    line-height: 118px;
  }

  @media (max-width: 1440px) {
    font-size: 80px;
    line-height: 100px;
  }

  @media (max-width: 1280px) {
    font-size: 70px;
    line-height: 90px;
  }

  @media (max-width: 1024px) {
    font-size: 60px;
    line-height: 75px;
  }

  @media (max-width: 600px) {
    font-size: 45px;
    line-height: 52px;
  }
}

.w-84 {
  width: 84%;

  @media (max-width: 820px) {
    width: 100%;
  }
}

.mt-130px {
  margin-top: 130px;

  @media (max-width: 1024px) {
    margin-top: 30px;
  }
}

.btn {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 3px solid $black-75;
    border-radius: 50px;
    width: 323px;
    height: 80px;

    @media (max-width: 1600px) {
      width: 315px;
    }

    @media (max-width: 1440px) {
      width: 280px;
    }

    @media (max-width: 1366px) {
      width: 267px;
    }

    @media (max-width: 1280px) {
      width: 257px;
      height: 72px;
    }

    @media (max-width: 1024px) {
      width: 232px;
      height: 55px;
    }

    @media (max-width: 820px) {
      width: 230px;
    }

    // @media (max-width: 768px) {
    //   width: 153px;
    // }

    @media (max-width: 600px) {
      width: 230px;
    }

    .main-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $primary-gradient;
      border: none;
      outline: none;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      transition: all 0.5s ease;

      @media (max-width: 1280px) {
        width: 50px;
        height: 50px;
      }

      @media (max-width: 1024px) {
        width: 35px;
        height: 35px;
      }
    }

    &:hover {
      .main-btn {
        width: 323px;
        height: 80px;
        border-radius: 50px;
        right: -2px;

        @media (max-width: 1680px) {
          width: 255px;
        }

        @media (max-width: 1600px) {
            width: 315px;
        }

        @media (max-width: 1440px) {
          width: 280px;
        }

        @media (max-width: 1366px) {
            width: 267px;
        }

        @media (max-width: 1280px) {
          width: 257px;
          height: 72px;
        }

        @media (max-width: 1024px) {
          width: 232px;
          height: 55px;
        }

        @media (max-width: 1024px) {
          width: 230px;
        }

        @media (max-width: 768px) {
          width: 153px;
        }

        @media (max-width: 600px) {
          width: 230px;
        }
      }

      .btn-circle {
        svg {
          transform: translateX(5px);
        }
      }
    }
  }

  &-circle {
    position: relative;
    z-index: 9;
    color: $white;
    left: -15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      left: -10px;
    }

    &.text-24 {
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 15px;
      }
    }

    svg {
      position: relative;
      right: -33px;
      transition: 0.3s ease;

      @media (max-width: 1680px) {
        right: -40px;
      }

      @media (max-width: 1024px) {
        right: -30px;
        width: 15px;
      }
    }
  }
}

.fw-semibold {
  font-weight: 600;
}

.bordered-heading {
  font-size: 95px;
  font-weight: 800;
  text-transform: uppercase;
  color: #000;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #45E3EF;
  -webkit-text-fill-color: #00000075;
  text-shadow: 0px 0px 20px rgb(13 202 240 / 46%);
  z-index: 0;
  margin-top: -20px;

  @media (max-width: 1200px) {
    font-size: 85px;
  }

  @media (max-width: 400px) {
    font-size: 55px;
    line-height: 80px;
  }
}

.edge-logo {
  width: 230px;

  @media (max-width: 600px) {
    width: 100px;
  }
}

.block-logo {
  width: 330px;
}

.home-2 {
  background-color: #111011;
}

@keyframes textLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes menu {
  0% {
    opacity: 0;
    left: 0%;
  }

  10% {
    opacity: 1;
    left: 0%;
  }

  100% {
    left: 50%;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hidden{
  display: none;
}

.section-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: $primary;

  @media (max-width: 820px) {
    flex-direction: column;
  }

  h5 {
    font-size: 45px;
    font-weight: 500;
    color: $primary;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-top: 10px;

    @media (max-width: 1440px) {
      font-size: 40px;
    }

    @media (max-width: 820px) {
      font-size: 35px;
      text-align: center;
    }

    @media (max-width: 400px) {
      font-size: 30px;
    }
  }
}

.title-decoration {
  display: flex;
  column-gap: 8px;
  margin-left: 35px;

  @media (max-width: 560px) {
    margin: 0;
  }

  span {
    width: 17px;
    height: 40px;
    transform: skewX(25deg);
    background: #D9D9D9;

    &:nth-of-type(2) {
      opacity: 0.9;
    }

    &:nth-of-type(3) {
      opacity: 0.8;
    }

    &:nth-of-type(4) {
      opacity: 0.7;
    }

    &:nth-of-type(5) {
      opacity: 0.6;
    }

    &:nth-of-type(6) {
      opacity: 0.5;
    }

    &:nth-of-type(7) {
      opacity: 0.4;
    }

    &:nth-of-type(8) {
      opacity: 0.3;
    }

    &:nth-of-type(9) {
      opacity: 0.2;
    }

    &:nth-of-type(10) {
      opacity: 0.1;
    }

    @media (max-width: 560px) {
      width: 12px;
      height: 30px;
    }
  }
}

.content-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 32px;
  text-transform: uppercase;

  @media (max-width: 1600px) {
    font-size: 37px;
    line-height: 53px;
    margin-bottom: 26px;
  }

  @media (max-width: 1366px) {
    font-size: 32px;
    line-height: 48px;
  }

  @media (max-width: 560px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.bg-black {
  background: $black-75;
}

a.but-link {
    color: $blue-2;
    text-decoration: underline;
    text-transform: capitalize;
    transition: all .3s ease;

    &:hover {
        color: $white;
    }
}
.p-t-0{
  padding-top: 0px;
}
.about-section, #characteristics{
  .padding-block {
    padding-top: 0px;
  }
  .solution-wrap {
    display: inline;
  }
}
.dataBox{
  .col-md-6{
    margin-top: 0px;
    margin-bottom: 32px;
  }
}
.about-section .padding-block{
  padding-top: 0px;
  margin-top: 26px;
}


.w-md-50 {
  @media (min-width: 768px) {
    width: 50% !important;
  }
}
.w-xl-25 {
  @media (min-width: 1280px) {
    width: 25% !important;
  }
}