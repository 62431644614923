nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 63px 100px;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1680px) {
    padding: 35px 70px;
  }

  // @media (max-width: 1440px) {
  //   padding: 25px 100px;
  // }

  // @media (max-width: 1280px) {
  //   padding: 25px 85px;
  // }

  @media (max-width: 1024px) {
    padding: 35px 50px;
  }

  @media (max-width: 600px) {
    padding: 20px 50px;
  }
}

.nav-items-wrap {
  @media (max-width: 1400px) {
    width: 90%;
  }

  @media (max-width: 860px) {
    width: fit-content;
  }
}

.header-bg {
  background: $dark;
  padding: 20px 100px;
  animation: fade-bg .5s linear forwards;

  @media (max-width: 1680px) {
    padding: 20px 70px;
  }

  @media (max-width: 560px) {
    padding: 20px 35px;
  }
}

@keyframes fade-bg {
  0% {
    background: rgba($color: $dark, $alpha: 0);
  }
  100% {
    background: rgba($color: $dark, $alpha: 1);
  }
}

.hamburger_menu {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  // @media (max-width: 1366px) {
  //   width: 79%;
  // }

  @media (max-width: 1280px) {
    // width: 85%;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1024px) {
    // width: 95%;
  }

  @media (max-width: 600px) {
    // display: none;
  }
}

.nav {
  &-links {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        margin: 0 20px;
        position: relative;

        @media (max-width: 1280px) {
          margin: 0 18px;
        }

        @media (max-width: 1024px) {
          margin: 0 14px;
        }

        &.active {
          &::before {
            width: 6px;
            height: 6px;
            background: $secondary;
            border-radius: 50%;
            bottom: -10px;
            position: absolute;
            content: "";
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 600px) {
              content: none;
            }
          }

          &:hover {
            &::before {
              animation: none;
              background: $secondary;
            }
          }

          a {
            color: $secondary;
          }
        }

        &:hover {
            color: $primary;
          &::before {
            width: 6px;
            height: 6px;
            background: $primary;
            border-radius: 50%;
            bottom: -10px;
            position: absolute;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            animation: menu 0.5s infinite;
            animation-iteration-count: 1;
          }

          a {
            color: $primary;

            &.custom-button {
                color: $white;
            }
          }
        }

        a {
          color: $white;
          text-transform: uppercase;
          font-size: 20px;
          transition: all .3s ease;

          @media (max-width: 1600px) {
            font-size: 18px;
          }

          @media (max-width: 1440px) {
            font-size: 15px;
          }

          @media (max-width: 1200px) {
            font-size: 13px;
          }

          @media (max-width: 1024px) {
            font-size: 12px;
          }

            &.custom-button {
                justify-content: center;
                align-items: center;
                padding: 10px 51px;
                gap: 10px;

                width: 129px;
                height: 45px;

                background: #aeacff;
                border-radius: 12px;
                color: $dark;
                transition: all .3s ease;
                flex: none;
                order: 8;
                flex-grow: 0;
                font-weight: 600;

                &:hover {
                  background: rgba($color: $primary, $alpha: 0.5);
                }

                @media (max-width: 1280px) {
                  border-radius: 8px;
                }

                @media (max-width: 860px) {
                  width: auto;
                }
            }
        }
      }
    }
  }

  &-btn {
    border-top: 1px solid $black-10;
    border-bottom: 1px solid $black-10;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;

    @media (max-width: 1024px) {
      padding: 8px 15px;
      width: 120px;
      font-size: 12px;
    }

    @media (max-width: 768px) {
      width: 115px;
    }

    .text-loop {
      display: flex;
      align-items: center;
      animation: textLoop 3s linear infinite;
      color: $black-10;

      span {
        content: "";
        width: 6px;
        height: 6px;
        margin: 0 12px;
        background: $secondary;
        border-radius: 5px;
      }
    }

    &:hover {
      border-top: 1px solid $secondary;
      border-bottom: 1px solid $secondary;
    }
  }
}

.banner-btn {
  padding: 15px 30px;
  border-radius: 12px;
  background: $primary;
  color: $dark;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  width: fit-content;
  transition: all .3s ease;
  cursor: pointer;

  @media (max-width: 1024px) {
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
  }

  &:hover {
    background: rgba($color: $primary, $alpha: 0.5);
    color: $white;
  }
}

.ham7 .top {
  stroke-dasharray: 40 82;
}

.ham7 .middle {
  stroke-dasharray: 40 111;
}

.ham7 .bottom {
  stroke-dasharray: 40 161;
}

.ham7.active .top {
  stroke-dasharray: 17 82;
  stroke-dashoffset: -62px;
}

.ham7.active .middle {
  stroke-dashoffset: 23px;
}

.ham7.active .bottom {
  stroke-dashoffset: -83px;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  width: 50px;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fff;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.button_container {
  background: transparent;
  display: none;
  position: relative;
  z-index: 55;

  @media (max-width: 860px) {
    display: block;
  }
}

// mobile menu
@media (max-width: 860px) {
  .hamburger_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, width 0.35s;
    z-index: 50;
    flex-direction: column;

    &:after {
      content: "";
      background: $black;
      right: -100%;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 0.35s ease;
    }

    &.open {
      opacity: 1;
      visibility: visible;
      height: 100%;

      &:after {
        right: 0;
      }

      li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.35s;

        &:nth-of-type(2) {
          animation-delay: 0.45s;
        }

        &:nth-of-type(3) {
          animation-delay: 0.55s;
        }

        &:nth-of-type(4) {
          animation-delay: 0.65s;
        }

        &:nth-of-type(5) {
          animation-delay: 0.75s;
        }

        &:nth-of-type(6) {
          animation-delay: 0.85s;
        }

        &:nth-of-type(7) {
          animation-delay: 0.95s;
        }

        &:nth-of-type(8) {
          animation-delay: 1.15s;
        }
      }
    }

    .nav-links {
      position: relative;
      height: 70%;
      top: 20%;
      transform: translateY(-50%);
      font-size: 35px;
      font-weight: 400;
      text-align: center;
      z-index: 100;
      right: 0%;

      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        height: 100%;

        li {
          display: block;
          min-height: 60px;
          position: relative;
          opacity: 0;

          a {
            display: block;
            position: relative;
            text-decoration: none;
            overflow: hidden;
            font-size: 25px;
            line-height: 30px;

            &:hover:after,
            &:focus:after,
            &:active:after {
              width: 100%;
            }

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0;
              transform: translateX(-50%);
              height: 3px;
              background: $black;
              transition: 0.35s;
            }
          }
        }
      }
    }
  }

  .nav-btn {
    position: absolute;
    z-index: 51;
    bottom: 5%;
    width: 200px;
    padding: 15px;
    font-size: 20px;
  }

  /*************** KeyFrames ***************/

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 100%;
    }

    100% {
      opacity: 1;
      left: 0;
    }
  }
}

.header-btn-wrap {
    &:hover {
        &::before {
            display: none;
        }
    }
}