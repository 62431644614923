.interview-page {
    .box-body {
        padding: 100px 100px 60px;

        @media (max-width: 1440px) {
            padding: 80px 70px 50;
        }

        @media (max-width: 1280px) {
            padding: 60px 55px 50px;
        }

        @media (max-width: 992px) {
            padding: 50px 40px;
        }

        @media (max-width: 600px) {
            padding: 40px 30px;
        }

        @media (max-width: 400px) {
            padding: 40px 20px;
        }
    }
}

.top-counter-wrap {
    display: grid;
    grid-template-columns: 85% 12%;
    align-items: center;
    gap: 30px;
    margin-bottom: 63px;

    @media (max-width: 600px) {
        grid-template-columns: 65% 23%;
    }
}

.progress-bar {
    width: 100%;
    height: 15px;
    background: $black-50;
    border-radius: 20px;
    position: relative;

    @media (max-width: 600px) {
        height: 10px;
    }
}

.counter-timer {
    display: flex;
    justify-content: right;
}

.question-wrap {
    width: 92%;

    p {
        color: $black-25;
        font-weight: 400;
    }
}

.process-wrap {
    position: relative;
    padding-left: 60px;

    @media (max-width: 1280px) {
        padding-left: 45px;
    }

    @media (max-width: 1024px) {
        padding-left: 35px;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        content: '';
        background: $primary-gradient;
        border-radius: 50%;

        @media (max-width: 1280px) {
            width: 30px;
            height: 30px;
        }

        @media (max-width: 1024px) {
            width: 25px;
            height: 25px;
        }
    }
}