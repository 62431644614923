$primary:           #AEACFF;
$secondary:         #45E3EF;
$blue-2:            #71B6DD;
$black:             #161616;
$black-75:          #191919;
$black-50:          #3B414F;
$black-25:          #9DA1AC;
$black-10:          #D2D2D2;
$gray:              #A6A6A6;
$gray-100:          #696969;
$gray-200:          #F1F1F1;
$dark:              #171717;
$primary-gradient:  linear-gradient(45deg, #0E74B5 0%, #E8495D 100%);
$dark-gradient:     linear-gradient(228deg, #4A4987 0%, #1D1D32 100%);
$size: 12px;
$time: 1;